<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Detail <small class="text-muted">Polling</small>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <dl>
              <dt>Question: </dt>
              <dd>{{ data.question }}</dd>
              <dt>Status: </dt>
              <dd>{{ data.is_expired ? 'Active' : 'Expired' }}</dd>
              <dt>Date: </dt>
              <dd>{{ data.date }}</dd>
            </dl>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <h5>Participant</h5>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(description)="data">
                  {{ data.item.description | dotConcat(100) }}
                </template>
              </b-table>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BRow, BCol,
    BCard, BCardBody, BCardHeader, BCardTitle,
    BFormGroup, BFormInput, BFormTextarea, BFormFile, BFormSelect, BButton, BTable, BDropdown, BDropdownItem,
    BImg
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
  import flatPickr from 'vue-flatpickr-component'

  export default {
    components: {
      BRow, BCol,
      BCard, BCardBody, BCardHeader, BCardTitle,
      BFormGroup, BFormInput, BFormTextarea, BFormFile, BFormSelect, BButton, 
      BImg, BTable, BDropdown, BDropdownItem,
      VueCropper,
      flatPickr
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        perPage: 50,
        pageOptions: [10, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'polling_value', label: 'Value' }
        ],
        active_role: null,
        data: {
          answer: []
        }
      }
    },
		created() {
      this.checkAuth()
      this.getDataPolling()
      this.getCorporate()
      this.active_role = localStorage.getItem('role')
    },
    computed: {
      corporate() {
        return this.$store.state.corporate.corporate
      },
      sortOptions() {
				// Create an options list from our fields
				return this.fields
					.filter(f => f.sortable)
					.map(f => ({ text: f.label, value: f.key }))
			},
      items() {
        let polling = this.data.answer
        this.totalRows = polling.length
        
        return polling
      }
    },
    methods: {
      onFiltered(filteredItems) {
				// Trigger pagination to update the number of buttons/pages due to filtering
				this.totalRows = filteredItems.length
				this.currentPage = 1
			},
      getCorporate() {
        this.$store.dispatch('corporate/loadCorporate')
      },
      getDataPolling() {
        let cid = localStorage.getItem('client_id')
        let id = this.$route.params.id
        const config = {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }
        // this.$store.dispatch('banner/loadBanner')
        this.$axios.get(`${cid}/member/corporate/polling/${id}`, config).then((response) => {
          this.data = response.data.data
        })
      },
      submit() {
        let id = this.$route.params.id
        let cid = localStorage.getItem('client_id')
        let input = this.data
        const data = new FormData()

        data.append('title', input.title)
        data.append('image', input.file.image)
        data.append('description', input.description)
        data.append('link', input.link)
        data.append('corporate', input.corporate)

        const config = {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }

        this.$axios.post(`${cid}/banner/${id}`, data, config)
          .then(res => {
            // Success code
            this.$bvToast.toast('Success', {
              title: 'Edit Banner',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-bottom-right',
              autoHideDelay: 3000
            })

            setTimeout(() => {
              this.$router.push({ path: '/banner' })
            }, 3000);
          })
          .catch(error => {
            console.log(error)
          })
      },

      showModalCrop() {
        this.modal_crop_image = true
      },
      resetImage() {
        this.$refs['file-input'].reset()

        this.data.file.image = null
        this.data.file.url = null
        this.data.file_raw = null
        this.data.image = null
      },
      setCropArea() {
        this.$refs.cropper.setCropBoxData({
          width: 512,
          height: 512
        })
      },
      resetCropImage() {
        if(!this.data.file_raw) {
          this.$refs['file-input'].reset()
        }
      },
      handleCropImage() {
        this.data.file.url = this.$refs.cropper.getCroppedCanvas().toDataURL()
        this.data.image = this.data.file.url

        let block = this.data.file.url.split(";");
        let content_type = block[0].split(":")[1]
        let real_data = block[1].split(",")[1];
        this.data.file.image = this.b64toBlob(real_data, content_type)
      },
      b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }
    }
  }
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
